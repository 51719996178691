import { RootState } from "store";
import querystring from "query-string";

export const selectProjectStats = (state: RootState) =>
  state.projectNext.projectStats;
export const selectProjectStatsError = (state: RootState) =>
  state.projectNext.projectStatsError;
export const selectProjectStatsLoading = (state: RootState) =>
  state.projectNext.projectStatsLoading;
export const selectProjectId = (state: RootState) =>
  state.projectNext.projectStats?.pk;
export const selectProjectCode = (state: RootState) =>
  state.projectNext.projectStats?.code;

export const selectProjectCompanyPart =
  (partPk: number) => (state: RootState) =>
    state.projectNext.projectCompanyParts.find((item) => item.pk === partPk);
export const selectProjectCompanyParts = (state: RootState) =>
  state.projectNext.projectCompanyParts;
export const selectProjectCompanyPartsError = (state: RootState) =>
  state.projectNext.projectCompanyPartsError;
export const selectProjectCompanyPartsLoading = (state: RootState) =>
  state.projectNext.projectCompanyPartsLoading;
export const selectProjectCompanyPartsCount = (state: RootState) =>
  state.projectNext.projectCompanyPartsCount;
export const selectProjectCompanyPartsLastQuery = (state: RootState) =>
  state.projectNext.projectCompanyPartsLastQuery;
export const selectProjectCompanyPartsSelectAll = (state: RootState) =>
  state.projectNext.projectCompanyPartsSelectAll;
export const selectProjectCompanyPartsSearchValue = (state: RootState) => {
  let searchValue = undefined;
  const projectCompanyPartsLastQuery =
    state.projectNext.projectCompanyPartsLastQuery;

  if (projectCompanyPartsLastQuery) {
    try {
      const queryParams = projectCompanyPartsLastQuery?.replace("?", ""); // removing "?" from params
      const parsed = querystring.parse(queryParams);
      searchValue = parsed?.search;
    } catch (error) {
      searchValue = undefined;
    } finally {
      return searchValue;
    }
  }
};

export const selectProjectCompanyOrders = (state: RootState) =>
  state.projectNext.projectCompanyOrders;
export const selectProjectCompanyOrdersCounts = (state: RootState) =>
  state.projectNext.projectCompanyOrdersCounts;
export const selectProjectCompanyOrdersError = (state: RootState) =>
  state.projectNext.projectCompanyOrdersError;
export const selectProjectCompanyOrdersLoading = (state: RootState) =>
  state.projectNext.projectCompanyOrdersLoading;
export const selectProjectCompanyOrdersLastQuery = (state: RootState) =>
  state.projectNext.projectCompanyOrdersLastQuery;

export const selectSelectedPartRows = (state: RootState) =>
  state.projectNext.selectedPartRows;

export const selectSelectedPart = (state: RootState) =>
  state.projectNext.selectedPart;
export const selectSelectedPartPk = (state: RootState) =>
  state.projectNext.selectedPart?.pk;

export const selectProjectUpdatePartLoading = (state: RootState) =>
  state.projectNext.projectUpdatePartLoading;

export const selectProjectDeletePartLoading = (state: RootState) =>
  state.projectNext.projectDeletePartLoading;

export const selectProjectBulkDeletePartLoading = (state: RootState) =>
  state.projectNext.projectBulkDeletePartLoading;

export const selectProjectClonePartLoading = (state: RootState) =>
  state.projectNext.projectClonePartLoading;

export const selectCreateRFQLoading = (state: RootState) =>
  state.projectNext.createRFQLoading;

export const selectUpdateRFQLoading = (state: RootState) =>
  state.projectNext.updateRFQLoading;

export const selectProjects = (state: RootState) => state.projectNext.projects;
export const selectProjectsCount = (state: RootState) =>
  state.projectNext.projectsCount;
export const selectProjectsLoading = (state: RootState) =>
  state.projectNext.projectsLoading;

export const selectProjectUpdateLoading = (state: RootState) =>
  state.projectNext.projectUpdateLoading;

export const selectProjectCreateLoading = (state: RootState) =>
  state.projectNext.projectCreateLoading;

export const selectCreateMultiContractRFQLoading = (state: RootState) =>
  state.projectNext.createMultiContractRFQLoading;

export const selectCreateMultiContractRFQData = (state: RootState) =>
  state.projectNext.createMultiContractRFQData;

export const selectExportMultiContractRFQExcelLoading = (state: RootState) =>
  state.projectNext.exportMultiContractRFQExcelLoading;

export const selectImportMultiContractRFQExcelLoading = (state: RootState) =>
  state.projectNext.importMultiContractRFQExcelLoading;

export const selectImportMultiContractRFQExcelReport = (state: RootState) =>
  state.projectNext.importMultiContractRFQExcelReport;

export const selectProjectBulkInsertPartLoading = (state: RootState) =>
  state.projectNext.projectBulkInsertPartLoading;

export const selectUploadBomFilesLoading = (state: RootState) =>
  state.projectNext.uploadBomFilesLoading;

export const selectFetchBomFilesUploadReportLoading = (state: RootState) =>
  state.projectNext.fetchBomFilesUploadReportLoading;
export const selectBomFilesUploadReport = (state: RootState) =>
  state.projectNext.bomFilesUploadReport;

export const selectBulkPartImportReport = (state: RootState) =>
  state.projectNext.bulkPartImportReport;

export const selectUploadBulkPartLoading = (state: RootState) =>
  state.projectNext.uploadBulkPartLoading;

export const selectProjectCompanyPartsFiltered = (state: RootState) =>
  state.projectNext.projectCompanyPartsFiltered;

export const selectProjectTemplates = (state: RootState) =>
  state.projectNext.projectTemplates;

export const selectProjectTemplatesLoading = (state: RootState) =>
  state.projectNext.fetchProjectTemplatesLoading;

export const projectSelectors = {
  selectProjectStats,
  selectProjectStatsError,
  selectProjectStatsLoading,
  selectProjectCompanyParts,
  selectProjectCompanyPartsError,
  selectProjectCompanyPartsLoading,
  selectProjectCompanyOrders,
  selectProjectCompanyOrdersCounts,
  selectProjectCompanyOrdersError,
  selectProjectCompanyOrdersLoading,
  selectSelectedPartRows,
  selectSelectedPart,
  selectSelectedPartPk,
  selectProjectUpdatePartLoading,
  selectProjectDeletePartLoading,
  selectProjectBulkDeletePartLoading,
  selectProjectClonePartLoading,
  selectProjects,
  selectProjectsCount,
  selectProjectsLoading,
  selectCreateMultiContractRFQLoading,
  selectCreateMultiContractRFQData,
  selectExportMultiContractRFQExcelLoading,
  selectImportMultiContractRFQExcelLoading,
  selectImportMultiContractRFQExcelReport,
  selectUpdateRFQLoading,
  selectProjectBulkInsertPartLoading,
  selectUploadBomFilesLoading,
  selectFetchBomFilesUploadReportLoading,
  selectBomFilesUploadReport,
  selectProjectCompanyPartsCount,
  selectProjectCompanyPartsLastQuery,
  selectProjectUpdateLoading,
  selectProjectCreateLoading,
  selectBulkPartImportReport,
  selectProjectCompanyPartsFiltered,
};
