import {
  APP_ENVIRONMENT,
  checkAppEnvironmentIs,
} from "@next/utils/constantUtils";
import { LicenseInfo as LicenseInfoV5 } from "@mui/x-data-grid-pro-v5";

export const initializeDataGridProV5License = () => {
  const isValidEnvironment = checkAppEnvironmentIs([
    APP_ENVIRONMENT.PRODUCTION,
    APP_ENVIRONMENT.STAGING,
    APP_ENVIRONMENT.AIRBUS,
  ]);

  if (import.meta.env.VITE_MUI_DATA_GRID_PRO_LICENSE_V5 && isValidEnvironment) {
    // https://v5.mui.com/x/introduction/licensing/
    LicenseInfoV5.setLicenseKey(
      import.meta.env.VITE_MUI_DATA_GRID_PRO_LICENSE_V5
    );
  }
};
