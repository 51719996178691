import React, { useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { GenericTextField } from "components/generic-text-field/generic-text-field.component";
import CustomButton from "@next/components/custom-button";
import { CompanyType } from "components/common/table-list/types";
import { SignupFormValues } from "../sign-up/sign-up";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedLanguage } from "@next/utils/browserUtils";
import axyaLogo from "assets/img/axya-logo.svg";
import { GenericPhoneNumberInput } from "components/utils/input/phone-number";
import {
  registerFormInitialValues,
  registerFormValidations,
} from "./create-company.definitions";
import { userOnboardingActions } from "services/user-onboarding";
import { profileService } from "services/profile";
import { useEnterKeyListener } from "@next/hooks/useEnterKeyListener";
import { createStyles, makeStyles } from "@mui/styles";
import { CountryList } from "@next/utils/countryList";
import CircularProgress from "@mui/material/CircularProgress";
import { debounce } from "lodash";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      gap: theme.spacing(6),
    },
    select: {
      textAlign: "left",
      "& .MuiSelect-select": {
        textAlignLast: "left",
      },
    },
    error: {
      color: theme.palette.error.main,
      marginLeft: "14px",
      marginTop: "4px",
      fontSize: "12px",
    },
    selectError: {
      "& fieldset": {
        borderColor: theme.palette.error.main,
      },
    },
    labelError: {
      color: theme.palette.error.main,
    },
    postalCodeLoaderItem: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    autoCompleteStyle: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.error.main,
      },
      textAlign: "left",
      "& .MuiSelect-select": {
        textAlignLast: "left",
      },
    },
  })
);

interface Props {
  signupData: SignupFormValues | null;
  captchaToken: string | null;
}

export const CreateCompany: React.FC<Props> = ({
  signupData,
  captchaToken,
}) => {
  const formRef = useRef<FormikProps<typeof registerFormInitialValues>>(null);
  const { t } = useTranslation();
  const activeLanguage = getSelectedLanguage();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isRegisterUserLoading } = useSelector(
    (state: any) => state.userOnboarding
  );
  const [loadingFetchAddressPostalCode, setLoadingFetchAddressPostalCode] =
    useState(false);

  const [selectedCountry, setSelectedCountry] = useState({
    Note: "",
    Country: "",
    ISO: "",
    Format: "",
    Regex: "",
  });
  const [postalErrorStatus, setPostalErrorStatus] = useState("");
  const [open, setOpen] = useState(false);
  const [postalCode, setPostalCode] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleRegister = async (values: typeof registerFormInitialValues) => {
    const userInformation = {
      user: {
        ...signupData,
        password2: signupData?.password1,
        language: activeLanguage,
      },
      company: {
        ...values,
      },
      captchaToken,
    };

    dispatch(userOnboardingActions.registerUser(userInformation, t, []));
  };

  useEnterKeyListener(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    formRef?.current?.submitForm();
  });

  return (
    <Formik
      initialValues={registerFormInitialValues}
      onSubmit={handleRegister}
      validationSchema={registerFormValidations}
      innerRef={formRef}
    >
      {({
        handleSubmit,
        errors,
        touched,
        values,
        setFieldValue,
        setFieldError,
      }) => {
        const {
          address: { city, province },
        } = values;
        const postalOnChange = async (pst) => {
          const value = pst.target.value;
          if (value) {
            setFieldValue("address.postal_code", value);
            setLoading(true);
            await profileService
              .fetchPostalCode(selectedCountry.ISO, value)
              .then((res) => {
                setPostalCode(res?.postal_code_list);
                setLoading(false);
                setPostalErrorStatus("");
              })
              .catch((error) => {
                setLoading(false);
                setPostalCode([]);
                const { response } = error;
                if (response.status === 400) {
                  setFieldError("address.postal_code", response.data.error);
                  setPostalErrorStatus(response.data.error);
                  setFieldValue("address", {
                    ...values.address,
                    city: response?.city || "",
                    province: response?.province || "",
                  });
                }
              });
          }
        };
        const postalCodeChange = debounce(postalOnChange, 500);

        const handleCountryChange = (select) => {
          setSelectedCountry(select);
          setFieldValue("address", {
            ...values.address,
            postal_code: "",
            city: "",
            province: "",
            country: select?.Country,
          });
        };
        const handleChangePostalCode = async (_, postalCode) => {
          if (selectedCountry.ISO && postalCode) {
            setLoadingFetchAddressPostalCode(true);
            setFieldValue("address.postal_code", postalCode);
            await profileService
              .fetchAddressByPostalCode(selectedCountry.ISO, postalCode)
              .then((response) => {
                setFieldValue("address", {
                  ...values.address,
                  city: response?.city || "",
                  postal_code: postalCode,
                  province: response?.province || "",
                });
              })
              .catch((error) => {
                const { response } = error;
                if (response.status == 400) {
                  setFieldError("address.postal_code", response.data.error);
                  setPostalErrorStatus(response.data.error);
                }
                setFieldValue("address", {
                  ...values.address,
                  city: "",
                  province: "",
                });
              })
              .finally(() => {
                setLoadingFetchAddressPostalCode(false);
              });
          } else {
            setFieldValue("address", {
              ...values.address,
              city: "",
              province: "",
            });
          }
        };
        return (
          <Form onSubmit={handleSubmit}>
            <Box className={classes.root}>
              <img src={axyaLogo} alt={"Axya Logo"} />
              <Typography variant="h4">
                {t("userOnboarding:createYourCompany")}
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    size="small"
                    color="secondary"
                    className={classes.select}
                  >
                    <InputLabel
                      id="companyType-label"
                      className={
                        errors.type && touched.type ? classes.labelError : ""
                      }
                    >
                      {t("userOnboarding:companyType")}
                    </InputLabel>
                    <Field
                      name="type"
                      as={Select}
                      labelId="companyType-label"
                      label={t("userOnboarding:companyType")}
                      placeholder={t("userOnboarding:companyType")}
                      className={
                        errors.type && touched.type ? classes.selectError : ""
                      }
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem value={CompanyType.FAB}>
                        {t("userOnboarding:seller")}
                      </MenuItem>
                      <MenuItem value={CompanyType.DO}>
                        {t("userOnboarding:buyer")}
                      </MenuItem>
                    </Field>
                    <ErrorMessage
                      name="type"
                      component="p"
                      className={classes.error}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <GenericTextField
                    name="name"
                    label={t("userOnboarding:companyName")}
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    size="small"
                    color="secondary"
                    className={
                      errors?.address?.country && touched?.address?.country
                        ? classes.autoCompleteStyle
                        : ""
                    }
                  >
                    <Field
                      name="address.country"
                      label={
                        <span
                          className={
                            errors?.address?.country &&
                            touched?.address?.country
                              ? classes.labelError
                              : ""
                          }
                        >
                          {t("userOnboarding:countryName")}
                        </span>
                      }
                      placeholder={t("userOnboarding:countryName")}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      as={() => (
                        <Autocomplete
                          size="small"
                          options={CountryList || []}
                          value={selectedCountry}
                          getOptionLabel={(option) => option.Country}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              key={params.id}
                              label={
                                <span
                                  className={
                                    errors?.address?.country &&
                                    touched?.address?.country
                                      ? classes.labelError
                                      : ""
                                  }
                                >
                                  {t("userOnboarding:countryName")}
                                </span>
                              }
                            />
                          )}
                          onChange={(_, select) => {
                            select
                              ? handleCountryChange(select)
                              : setFieldError(
                                  "address.country",
                                  "This Field is required"
                                );
                          }}
                        />
                      )}
                    ></Field>
                    <ErrorMessage
                      name="address.country"
                      component="p"
                      className={classes.error}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <GenericTextField
                    name="address.street"
                    label={t("userOnboarding:companyAddress")}
                    fullWidth
                    size="small"
                  />
                </Grid>
                {selectedCountry?.Country && (
                  <>
                    <Grid item xs={4}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        size="small"
                        color="secondary"
                        className={
                          errors?.address?.postal_code &&
                          touched?.address?.postal_code
                            ? classes.autoCompleteStyle
                            : ""
                        }
                      >
                        <Autocomplete
                          size="small"
                          open={open}
                          onOpen={() => {
                            setOpen(true);
                          }}
                          options={postalCode}
                          onClose={() => {
                            setOpen(false);
                          }}
                          loading={loading}
                          getOptionLabel={(option) => option}
                          onChange={handleChangePostalCode}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              key={params.id}
                              label={
                                <span
                                  className={
                                    errors?.address?.postal_code &&
                                    touched?.address?.postal_code
                                      ? classes.labelError
                                      : ""
                                  }
                                >
                                  {t("userOnboarding:postalCode")}
                                </span>
                              }
                              onChange={postalCodeChange}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {loading ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                        {postalErrorStatus ? (
                          <span
                            className={
                              postalErrorStatus.includes("postal code")
                                ? classes.labelError
                                : null
                            }
                            style={{
                              fontSize: "12px",
                              marginLeft: "auto",
                              marginTop: "5px",
                            }}
                          >
                            {postalErrorStatus}
                          </span>
                        ) : (
                          <ErrorMessage
                            name="address.postal_code"
                            component="p"
                            className={classes.error}
                          />
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <GenericTextField
                        name="address.province"
                        label={t("userOnboarding:province")}
                        fullWidth
                        size="small"
                        onChange={(e) => {
                          setFieldValue("address.province", e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <GenericTextField
                        name="address.city"
                        label={t("userOnboarding:city")}
                        fullWidth
                        size="small"
                        onChange={(e) =>
                          setFieldValue("address.city", e.target.value)
                        }
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <GenericPhoneNumberInput
                    name="phone_number"
                    label={t("userOnboarding:companyPhoneNumber")}
                    defaultCountry="ca"
                    onlyCountries={["us", "ca"]}
                    value={values.phone_number}
                    onChange={(value: string) => {
                      if (value === values.phone_number) return;

                      setFieldValue("phone_number", value);
                    }}
                    size="small"
                  />
                </Grid>
              </Grid>
              <CustomButton
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                loading={isRegisterUserLoading}
                data-pid={"btnRegisterCompanyOnAxya"}
                disabled={loadingFetchAddressPostalCode || (!city && !province)}
              >
                {t("common:create")}
              </CustomButton>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};
