/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLoadingRows } from "helpers/data-view.helper-v5";
import {
  selectTendersData,
  selectTendersLoading,
} from "@next/modules/workspace/redux/selectors";
import {
  selectMarketplaceActiveRFQsList,
  selectMarketplaceActiveRFQsListCount,
} from "../../redux/selectors";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { marketplaceActions } from "services/marketplace";
import { getTendersTableColumns } from "./tenders-table.columns";
import { getMarketplaceRequestsTableColumns } from "./marketplace-requests-table.columns";
import { RequestStatus, RequestType } from "../../redux";
import qs from "query-string";
import { useTranslation } from "react-i18next";

export const useMarketplaceRequestsTableData = (
  type: RequestType,
  status: RequestStatus,
  page: number,
  pageSize: number,
  fetchMarketPlaceData: boolean,
  selectedRow: number
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const columns = useMemo(
    () =>
      type === RequestType.TENDERS
        ? getTendersTableColumns(t)
        : getMarketplaceRequestsTableColumns(t, selectedRow),
    [
      type,
      t("marketplaceRequests:table:company"), //a hack to re-compute on language change
      selectedRow,
    ]
  );

  const tenders = useSelector(selectTendersData);
  const activeRFQs = useSelector(selectMarketplaceActiveRFQsList);
  const activeRFQsCount = useSelector(selectMarketplaceActiveRFQsListCount);

  const tendersLoading = useSelector(selectTendersLoading);
  const activeRFQsLoading = useSelector(
    (state: any) => state.marketplace.isLoading
  );

  const [gridData, setGridData] = useState<{ rows: any[]; columns: any[] }>({
    rows: [],
    columns: [],
  });

  useEffect(() => {
    if (type === RequestType.TENDERS) {
      dispatch(workspaceNextActions.fetchTendersRequest());
    }
  }, [type]);

  useEffect(() => {
    if (type !== RequestType.TENDERS) {
      const query: Record<string, any> = {
        page,
        pageSize,
      };

      query.is_private = type === RequestType.PREMIUM ? false : true;
      if (status === RequestStatus.INTERESTED) query.intentToBid = true;
      else if (status === RequestStatus.NOT_INTERESTED) query.hidden = true;

      dispatch(
        marketplaceActions.fetchActiveRFQList(undefined, qs.stringify(query))
      );
    }
  }, [type, status, page, pageSize, fetchMarketPlaceData]);

  useEffect(() => {
    if (tendersLoading || activeRFQsLoading)
      setGridData(getLoadingRows(columns));
    else if (type === RequestType.TENDERS) {
      setGridData({ rows: tenders, columns });
    } else {
      setGridData({
        rows: activeRFQs,
        columns,
      });
    }
  }, [tenders, activeRFQs, tendersLoading, activeRFQsLoading, selectedRow]);

  return {
    gridData,
    count:
      type === RequestType.TENDERS
        ? gridData.rows.length
        : activeRFQsCount.total,
  };
};
