import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Container, Theme } from "@mui/material";
import { CreateCompany } from "./components/create-company/create-company";
import { Signup, SignupFormValues } from "./components/sign-up/sign-up";
import { useQuery } from "@next/hooks/useQuery";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      background: theme.palette.common.white,
      borderRadius: "24px",
      padding: theme.spacing(6),
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
    },
  })
);

enum RegisterSteps {
  SignUp,
  CreateCompany,
}

export const Register = () => {
  const classes = useStyles();
  const [query] = useQuery();
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const { email, isJoinTeam, fromPortal, first_name, last_name } = query;
  const [step, setStep] = useState<RegisterSteps>(RegisterSteps.SignUp);
  const [signupData, setSignupData] = useState<SignupFormValues | null>(null);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const sitekey = process.env.REACT_APP_CAPTCHA_PUBLIC_KEY;

  const handleCaptchaChange = (token: string | null) => {
    setCaptchaToken(token);
  };

  const handleSignUp = (signupValues: SignupFormValues) => {
    setSignupData(signupValues);
    setStep(RegisterSteps.CreateCompany);
  };

  const renderStep = () => {
    switch (step) {
      case RegisterSteps.SignUp:
        return (
          <Signup
            onSignUp={handleSignUp}
            email={email as string}
            first_name={first_name as string}
            last_name={last_name as string}
            isJoinTeam={isJoinTeam ? isJoinTeam === "true" : !!email}
            fromPortal={fromPortal ? fromPortal === "true" : false}
          />
        );
      case RegisterSteps.CreateCompany:
        return (
          <>
            <CreateCompany
              signupData={signupData}
              captchaToken={captchaToken}
            />
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={sitekey}
              onChange={handleCaptchaChange}
            />
          </>
        );

      default:
        return <></>;
    }
  };

  return (
    <div className={"auth"}>
      <Container maxWidth="sm" className={classes.root}>
        {renderStep()}
      </Container>
    </div>
  );
};
