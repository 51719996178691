import "./init";
import React from "react";
import { createRoot } from "react-dom/client";

// I18n
import { I18nextProvider } from "react-i18next";
import i18n from "./assets/configi18n/i18n";

// Redux
import { persistor, store } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";

// Google Tag Manager
import GTM from "react-gtm-module";

import App from "@next/components/App";

// Styling Import
import "assets/styles/styles.scss";
import { initAmplitude } from "@next/utils/amplitudeUtils";
import {
  APP_ENVIRONMENT,
  checkAppEnvironmentIs,
} from "@next/utils/constantUtils";

import OverridesBar from "@next/components/App/overrides-bar";
import { frontendUrl } from "urls";
const isProdEnvironment = checkAppEnvironmentIs([APP_ENVIRONMENT.PRODUCTION]);

// Initialize GTM with id from .env
if (
  isProdEnvironment &&
  import.meta.env.VITE_GTM_ID &&
  import.meta.env.VITE_AMQ_IFRAME_GTM_ID
) {
  GTM.initialize({
    // For AMQ IFrame we are using different GTM for them.
    gtmId:
      window.location.pathname === frontendUrl.publicRfqAmq
        ? import.meta.env.VITE_AMQ_IFRAME_GTM_ID
        : import.meta.env.VITE_GTM_ID,
  });
}

// Initialize Amplitude
initAmplitude();

const root = createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <OverridesBar />
    {/*include our i18n configuration file to be able to use it in all our Application. */}
    <I18nextProvider i18n={i18n as any}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </I18nextProvider>
  </Provider>
);
